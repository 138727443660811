import React from "react";
import aboutContainer from '../styles/about.module.scss';

export default function NotFoundPage() {
  return (
    <div className={aboutContainer.container}>
        <p>
            Page not found
        </p>
    </div>
  )
}